import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faTimes, faDesktop, faComment, faTrash, faBuilding, faTrashAlt, faAngleRight, faAngleLeft, faSpinner, faBan, faUserSlash, faSearch, faUsers, faFile, faBlog, faVolumeUp, faVolumeSlash, faCaretDown, faArrowLeft, faQuestionCircle, faPencil as FasPencil, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faEdit, faComment as farComment, faSmileWink, faMehRollingEyes, faMeh, faUser as farUser } from '@fortawesome/pro-regular-svg-icons';
import { faCalendarAlt, faUserAstronaut, faComments, faBell, faList, faPencil, faHistory, faCog, faUser, faEnvelope, faSignOutAlt, faBars, faPenToSquare, faCopy, faEye, faArrowRightFromBracket } from '@fortawesome/pro-light-svg-icons';
// import {  } from '@fortawesome/pro-thin-svg-icons'
import { faGithub, faTwitter, faSlideshare, faSpeakerDeck } from '@fortawesome/free-brands-svg-icons';
library.add(faTimes, faDesktop, faUser, faComment, faComment, faPencil, faTrash, faBuilding, faTrashAlt, faAngleRight, faAngleLeft, faBars, faSpinner, faBan, faUserSlash, faSearch, faHistory, faUsers, faComments, faBell, faCog, faSignOutAlt, faEdit, farComment, faEnvelope, faGithub, faTwitter, faFile, faBlog, faSlideshare, faSpeakerDeck, farUser, faCalendarAlt, faVolumeUp, faVolumeSlash, faCaretDown, faArrowLeft, faQuestionCircle, faList, faSmileWink, faMehRollingEyes, faMeh, faUserAstronaut, faPenToSquare, FasPencil, faPlus, faCopy, faEye, faArrowRightFromBracket);
document.addEventListener('turbo:load', () => {
    dom.watch();
});
